import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNode, faReact, faDocker } from "@fortawesome/free-brands-svg-icons";
import { SiGraphql, SiMysql, SiTypescript } from "react-icons/si";

import Layout from "../components/Layout";
import Project from "../components/Project";
import Section from "../components/Section";
import AnimatedLink from "../components/AnimatedLink";
import SEO from "../components/SEO";

const IndexPage = ({ data, location }) => {
    const { featuredProject, file } = data;
    const headerChildren = (
        <Fragment>
            <h1>Hi, I'm Dylan</h1>
            <div className="mt-2 header-meta text-secondary">
                <div>
                    I'm a <span className="heavy">web developer</span> with a
                    passion for making things happen
                </div>
            </div>
        </Fragment>
    );

    return (
        <Layout headerChildren={headerChildren} path={location.pathname}>
            <SEO title="Home" pathname="/" />
            <Section>
                <div className="max-w-screen-sm mx-auto">
                    <h2 className="mb-4 text-center heading">What I Do</h2>
                    <div
                        className="mb-2 text-center text-secondary"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        I'm a full stack web developer who is on an endless
                        quest to make the web a better place by making one great
                        application at a time. Thanks for taking the time to
                        check out my piece of the web!
                    </div>
                </div>
            </Section>
            <Section>
                <div className="max-w-screen-sm mx-auto">
                    <h2 className="mb-4 text-center heading">
                        What's In My Toolbox
                    </h2>
                    <div
                        className="w-full"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <div className="flex flex-wrap w-full my-4 justify-evenly">
                            {techIcons.map(({ icon, name }, index) => (
                                <div
                                    className="w-1/3 my-6 text-center"
                                    key={`tech-icon-${index}`}
                                >
                                    {icon}
                                    <span className="block mt-2 text-xs">
                                        {name}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div
                            className="mb-2 text-center text-secondary"
                            data-aos="fade-up"
                            data-aos-once="true"
                        >
                            My skill set is robust and ever evolving! From
                            application containerization, to Jamstack
                            applications, to building APIs, I am super
                            comfortable anywhere in the development workflow and
                            application lifecycle.
                        </div>
                    </div>
                </div>
            </Section>
            <Section fluid>
                <div className="max-w-screen-sm px-4 mx-auto">
                    <h2 className="mb-4 text-center heading">
                        What I've Worked On Lately
                    </h2>
                    <div
                        className="mb-2 text-center text-secondary"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        Check out my most recent project below. If you like what
                        you see or would like to contribute,
                        <AnimatedLink
                            className="mx-1 text-primary"
                            path="/#Contact"
                            label="get in touch"
                            borderColor="#2B1F44"
                        />
                        and we can have a chat!
                    </div>
                </div>
                <Project
                    project={featuredProject}
                    waveTop={{
                        display: true,
                        style: 1,
                        backgroundColor: "#FFF",
                        fillColor: featuredProject.backgroundColor,
                        isTop: true,
                    }}
                    waveBottom={{
                        display: true,
                        style: 1,
                        backgroundColor: "#FFF",
                        fillColor: featuredProject.backgroundColor,
                        isTop: false,
                    }}
                    image={file.childCloudinaryAsset.fluid}
                />
                <div className="container px-4 mx-auto md:px-0">
                    <h2 className="mb-4 text-center heading">
                        Want to see more of my work?
                    </h2>
                    <div className="text-center text-secondary">
                        If you're interested in checking out more of my work,
                        head over to my
                        <AnimatedLink
                            className="mx-1 text-primary"
                            path="/projects"
                            label="projects page"
                            borderColor="#2B1F44"
                        />
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

const techIcons = [
    {
        icon: (
            <SiTypescript
                className="mx-auto tech-icon"
                style={{ color: "#4272BA" }}
            />
        ),
        name: "TypeScript",
    },
    {
        icon: (
            <FontAwesomeIcon
                className="mx-auto tech-icon"
                icon={faReact}
                style={{ color: "#46B5D7" }}
            />
        ),
        name: "React",
    },
    {
        icon: (
            <SiGraphql
                className="mx-auto tech-icon"
                style={{ color: "#CC46A2" }}
            />
        ),
        name: "GraphQL",
    },
    {
        icon: (
            <FontAwesomeIcon
                className="mx-auto tech-icon"
                icon={faDocker}
                style={{ color: "#2081E8" }}
            />
        ),
        name: "Docker",
    },
    {
        icon: (
            <FontAwesomeIcon
                className="mx-auto tech-icon"
                icon={faNode}
                style={{ color: "#6BAF06" }}
            />
        ),
        name: "Node.js",
    },
    {
        icon: <SiMysql className="mx-auto tech-icon" />,
        name: "MySQL",
    },
];

export const indexQuery = graphql`
    query {
        featuredProject: projectsJson {
            id
            name
            technologies
            description
            link
            backgroundColor
            linkBorderColor
            shadowColor
            imageName
        }
        file(name: { eq: "renorun" }) {
            childCloudinaryAsset {
                fluid {
                    ...CloudinaryAssetFluid
                }
            }
        }
    }
`;

export default IndexPage;
